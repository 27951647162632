import { AccessTime, DateRange, Inbox } from '@mui/icons-material'
import { Paper, Stack, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { CacheKeys } from '../../constants/cacheKeys'
import MyWorkQueueDetailsModel from '../../models/MyWorkQueueDetailsModel'
import { getMyWorkQueueDetails } from '../../services/workQueueService'

export const MyWorkQueue = () => {
  const { data: MyQueueItems } = useQuery<MyWorkQueueDetailsModel>({
    queryKey: [CacheKeys.GetMyWorkQueueDetails],
    queryFn: () => getMyWorkQueueDetails(),
    staleTime: 500000,
  })

  const getCurrentDate = () => {
    return new Date().toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
  }

  return (
    <Paper style={{ marginTop: '16px' }} elevation={0}>
      <Stack spacing={1} padding={1}>
        <Typography variant="body2">{getCurrentDate()}</Typography>
        {[
          {
            icon: <Inbox style={{ fontSize: 30, color: '#6e6e6e' }} />,
            label: (
              <Typography variant="overline" sx={{ lineHeight: '1.2rem' }}>
                ASSIGNED TO ME
              </Typography>
            ),
            value: MyQueueItems?.assigned ?? '0',
          },
          {
            icon: <AccessTime style={{ fontSize: 30, color: '#6e6e6e' }} />,
            label: (
              <Typography variant="overline" sx={{ lineHeight: '1.2rem' }}>
                DUE TODAY
              </Typography>
            ),
            value: MyQueueItems?.due ?? '0',
          },
          {
            icon: <DateRange style={{ fontSize: 30, color: '#6e6e6e' }} />,
            label: (
              <Typography variant="overline" sx={{ lineHeight: '1.2rem' }}>
                OVERDUE
              </Typography>
            ),
            value: MyQueueItems?.overdue ?? '0',
          },
        ].map((item, index) => (
          <Paper
            key={index}
            elevation={0}
            square={false}
            style={{ paddingTop: '10px', paddingLeft: '10px', paddingBottom: '20px', backgroundColor: '#fafafa' }}
          >
            <Stack direction="row" spacing={2}>
              {item.icon}
              <Stack>
                <Typography variant="body2">{item.label}</Typography>
                <Typography variant="h4">{item.value}</Typography>
              </Stack>
            </Stack>
          </Paper>
        ))}
      </Stack>
    </Paper>
  )
}
export default MyWorkQueue
