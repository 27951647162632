import SendIcon from '@mui/icons-material/Send'
import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import PhoenixModalHeader from './PhoenixModalHeader'

interface IFormInput {
  responseText: string
}

interface RejectErrorModalProps {
  isOpen: boolean
  onCancel: () => void
  onReject: (responseText: string) => void
}

const RejectErrorModal = ({ isOpen, onCancel, onReject }: RejectErrorModalProps) => {
  const { control, handleSubmit, register, reset, formState } = useForm({
    defaultValues: {
      responseText: '',
    },
  })

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    onReject(data.responseText)
    reset()
  }

  const closeAndClear = () => {
    onCancel()
    reset()
  }

  return (
    <Dialog fullWidth open={isOpen} maxWidth={'md'} data-testid={'reject-modal'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PhoenixModalHeader title={'Reject Response'} isErrorColor handleClose={closeAndClear} />
        <DialogContent>
          <Controller
            name="responseText"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                {...register('responseText', { required: true })}
                fullWidth
                label="Response Text"
                multiline
                rows={3}
                InputLabelProps={{ shrink: true }}
                data-testid="response-text"
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button data-testid="cancel-reject-btn" size={'small'} variant="text" onClick={closeAndClear}>
            Cancel
          </Button>
          <Button
            disabled={!formState.isValid}
            size={'small'}
            variant="contained"
            color="primary"
            endIcon={<SendIcon />}
            type="submit"
          >
            Send
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default RejectErrorModal
