import { ConfirmMatchDTO } from '../models/ConfirmMatchDTO'
import { MatchingCoverageResponse } from '../models/WorkItemDetails/MatchingCoverageResponse'
import { SuggestedMatchModel } from '../models/WorkItemDetails/SuggestedMatchModel'
import { USRCarrierError } from '../models/WorkItemDetails/USRCarrierError'
import { UnmatchedTransactionModel } from '../models/WorkItemDetails/UnmatchedTransactionModel'
import WorkItemDetailsModel from '../models/WorkItemDetails/WorkItemDetailsModel'
import api, { ApiResponse } from '../utils/apiService'
import { Endpoints } from '../utils/endpoint'

export const getWorkItemDetails = async (workItemId: string) => {
  const response: ApiResponse<WorkItemDetailsModel> = await api.get(
    `${Endpoints.WorkQueue}/workItem/details/${workItemId}`
  )
  return response.data
}

export const getUnmatchedTransaction = async (workItemId: string) => {
  const response: ApiResponse<UnmatchedTransactionModel> = await api.get(
    `${Endpoints.WorkQueue}/workItem/unmatchedtransaction/${workItemId}`
  )
  return response.data
}

export const getSuggestedMatches = async (workItemId: string) => {
  const response: ApiResponse<SuggestedMatchModel[]> = await api.get(
    `${Endpoints.WorkQueue}/workItem/suggestedMatches/${workItemId}`
  )
  return response.data
}

export const getUSRCarrierErrorNotifications = async (workItemId: string) => {
  const response: ApiResponse<USRCarrierError> = await api.get(
    `${Endpoints.WorkQueue}/workItem/carrierErrorNotifications/${workItemId}`
  )
  return response.data
}

export const resolveUSRCarrierError = async (
  userAccepted: boolean,
  workItemId: string,
  responseText?: string
): Promise<number> => {
  const response: ApiResponse<number> = await api.post(
    `${Endpoints.WorkQueue}/workItem/carrierErrorNotifications/${workItemId}`,
    {
      userAccepted: userAccepted,
      workItemGuid: workItemId,
      responseText: responseText,
      entityType: 'unitstat',
    }
  )
  return response.data
}

export const matchPolicyToCoverage = async (workItemId: string, req: ConfirmMatchDTO) => {
  const response: ApiResponse<void> = await api.post(`${Endpoints.WorkQueue}/workItem/${workItemId}/match`, {
    coverageId: req.coverageId,
    comboId: req.comboId,
  })
  return response.data
}

export const findMatchingCoverage = async (coverageID: string) => {
  const response: ApiResponse<MatchingCoverageResponse> = await api.get(
    `${Endpoints.WorkQueue}/workItem/findMatchingCoverage/${coverageID}`
  )
  return response.data
}
