import { LocationOn, Person } from '@mui/icons-material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import InfoIcon from '@mui/icons-material/Info'
import WorkIcon from '@mui/icons-material/Work'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { FieldGroup, PhoenixBaseCard, PhoenixLink } from 'componix'
import { useEffect } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { MatchingCoverageResponse } from '../../models/WorkItemDetails/MatchingCoverageResponse'
import { SuggestedMatchModel } from '../../models/WorkItemDetails/SuggestedMatchModel'
import { UnmatchedTransactionModel } from '../../models/WorkItemDetails/UnmatchedTransactionModel'
import { findMatchingCoverage } from '../../services/workItemService'
import { addressFormatter } from '../../utils/addressFormatter'
import PhoenixModalHeader from '../Modals/PhoenixModalHeader'

interface IFormInput {
  coverageID: string
}

export interface MatchPolicyToCoverageModalProps {
  data: UnmatchedTransactionModel | null | undefined
  isOpen: boolean
  handleClose: () => void
  setFoundMatch: (match: SuggestedMatchModel | null) => void
  match: SuggestedMatchModel | null
  isMatchLoading: boolean
  activeStep: number
  handleConfirmMatch: () => void
}

const MatchPolicyToCoverageModal = ({
  data,
  isOpen,
  handleClose,
  setFoundMatch,
  match,
  isMatchLoading,
  activeStep,
  handleConfirmMatch,
}: MatchPolicyToCoverageModalProps) => {
  const doesPolicyHaveMatch = !!match
  const { control, handleSubmit, register, watch, reset, setError } = useForm({
    mode: 'all',
    defaultValues: {
      coverageID: '',
    },
  })

  const formCoverageId = watch('coverageID')

  const { refetch: fetchMatchingCoverage, isRefetching: isFetchingMatchingCoverage } =
    useQuery<MatchingCoverageResponse>({
      queryKey: ['findMatchingCoverage', formCoverageId],
      queryFn: () => findMatchingCoverage(formCoverageId),
      // These two params stop the query from automatically running on page load
      refetchOnWindowFocus: false,
      enabled: false,
    })

  const onSubmitFindMatchingCoverage: SubmitHandler<IFormInput> = async () => {
    const result = await fetchMatchingCoverage()
    if (result.data) {
      const match: SuggestedMatchModel = {
        suggestionID: '',
        fein: result.data.fein,
        combo: {
          identifier: result.data.comboId,
          guid: result.data.comboGuid,
        },
        coverage: {
          identifier: result.data.coverageId,
          guid: result.data.coverageGuid,
        },
        employer: result.data.primaryName,
        address: addressFormatter(result.data?.address) as string,
        open: true,
      }

      setFoundMatch(match)
    } else {
      setError('coverageID', { message: 'No records to display.' })
    }
  }

  useEffect(() => {
    if (isOpen) {
      // When opening the modal, reset the form
      reset()
    }
  }, [isOpen])

  return (
    <Dialog fullWidth maxWidth={'sm'} open={isOpen}>
      <PhoenixModalHeader title={'Match Policy To Coverage'} isErrorColor={false} handleClose={handleClose} />
      <DialogContent>
        <Box display={'flex'} justifyContent={'center'} mb={2}>
          <Stepper sx={{ minWidth: '240px' }} activeStep={activeStep}>
            <Step>
              <StepLabel>Match</StepLabel>
            </Step>
            <Step>
              <StepLabel>Results</StepLabel>
            </Step>
          </Stepper>
        </Box>
        {activeStep === 0 ? (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <PhoenixBaseCard cardTitle={'Transaction Details'} variantType={'Secondary'}>
                <Box p={1}>
                  <FieldGroup label={'Combo ID'} value={data?.combo?.identifier} />
                  <FieldGroup label={'Coverage ID'} value={data?.coverage?.identifier} />
                  <FieldGroup icon={<Person />} label={'Primary Name'} value={data?.employer} />
                  <FieldGroup icon={<LocationOn />} label={'Address'} value={addressFormatter(data?.address)} />
                  <FieldGroup icon={<WorkIcon />} label={'FEIN'} value={data?.fein} />
                </Box>
              </PhoenixBaseCard>
            </Grid>
            <Grid item xs={6}>
              {doesPolicyHaveMatch ? (
                <>
                  <PhoenixBaseCard cardTitle={'Match to'} variantType={'Secondary'}>
                    <Box p={1}>
                      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                        <FieldGroup label={'Combo ID'} value={match?.combo?.identifier} />
                        <Button
                          variant="text"
                          onClick={() => {
                            setFoundMatch(null)
                            reset()
                          }}
                        >
                          RESET
                        </Button>
                      </Box>
                      <FieldGroup label={'Coverage ID'} value={match?.coverage?.identifier} />
                      <FieldGroup icon={<Person />} label={'Primary Name'} value={match?.employer} />
                      <FieldGroup icon={<LocationOn />} label={'Address'} value={match?.address} />
                      <FieldGroup icon={<WorkIcon />} label={'FEIN'} value={match?.fein} />
                    </Box>
                  </PhoenixBaseCard>
                </>
              ) : (
                <>
                  <PhoenixBaseCard cardTitle={'Match to Existing Coverage Group'} variantType={'Secondary'}>
                    <Box mx={1} my={2} sx={{ width: '100%' }}>
                      <form onSubmit={handleSubmit(onSubmitFindMatchingCoverage)}>
                        <Grid container>
                          <Grid item xs={10}>
                            <Controller
                              name="coverageID"
                              control={control}
                              render={({ field, fieldState }) => (
                                <TextField
                                  {...field}
                                  {...register('coverageID', {
                                    maxLength: {
                                      message: 'Coverage ID must not contain more than 7 characters.',
                                      value: 7,
                                    },
                                    required: {
                                      message: 'Please enter a value.',
                                      value: true,
                                    },
                                    pattern: {
                                      message: 'Non numeric characters are not allowed.',
                                      value: /^[0-9]*$/,
                                    },
                                  })}
                                  inputProps={{ type: 'search' }}
                                  fullWidth
                                  label="Coverage ID"
                                  size={'small'}
                                  variant={'outlined'}
                                  error={fieldState.invalid}
                                  helperText={fieldState.error?.message}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <LoadingButton
                              loading={isFetchingMatchingCoverage}
                              variant={'contained'}
                              sx={{
                                padding: '6px',
                                margin: '2px',
                                marginLeft: 1,
                                minWidth: '30px',
                              }}
                              type="submit"
                            >
                              <ChevronRightIcon />
                            </LoadingButton>
                          </Grid>
                        </Grid>
                      </form>
                    </Box>
                  </PhoenixBaseCard>
                  <Grid container py={2}>
                    <Grid item xs={5} alignContent={'center'}>
                      <Divider />
                    </Grid>
                    <Grid item xs={2} textAlign={'center'}>
                      <Typography variant={'overline'}>OR</Typography>
                    </Grid>
                    <Grid item xs={5} alignContent={'center'}>
                      <Divider />
                    </Grid>
                  </Grid>
                  <PhoenixBaseCard cardTitle={'Create Coverage Group'} variantType={'Secondary'}>
                    <Box mx={1} mt={1.5}>
                      <Stack flexDirection={'row'} alignItems={'center'} mb={1}>
                        <InfoIcon color={'secondary'} sx={{ mr: 1 }} />
                        <Typography variant={'caption'} lineHeight={1.5}>
                          The system will automatically assign a Combo ID if one is not entered.
                        </Typography>
                      </Stack>
                      <Grid container>
                        <Grid item xs={10}>
                          <TextField
                            label="Combo ID"
                            variant="outlined"
                            size={'small'}
                            helperText={'Optional'}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <LoadingButton
                            variant={'contained'}
                            sx={{
                              padding: '6px',
                              margin: '2px',
                              marginLeft: 1,
                              minWidth: '30px',
                            }}
                          >
                            <ChevronRightIcon />
                          </LoadingButton>
                        </Grid>
                      </Grid>
                    </Box>
                  </PhoenixBaseCard>
                </>
              )}
            </Grid>
          </Grid>
        ) : (
          <Box p={1}>
            <FieldGroup
              label={'Combo ID'}
              value={
                <PhoenixLink value={String(match?.coverage.identifier)} to={`/coverages/${match?.coverage.guid}`} />
              }
            />
            <FieldGroup
              label={'Coverage ID'}
              value={<PhoenixLink value={String(match?.combo.identifier)} to={`/coverages/${match?.combo.guid}`} />}
            />
            <FieldGroup label={'Policy Number'} value={data?.transactionDetails.policyInformation.policyNumber} />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {activeStep === 0 ? (
          <>
            <Button onClick={handleClose} disabled={isMatchLoading}>
              Cancel
            </Button>
            <LoadingButton
              variant={'contained'}
              disabled={!doesPolicyHaveMatch || isMatchLoading}
              onClick={() => handleConfirmMatch()}
              loading={isMatchLoading}
            >
              Confirm Match
            </LoadingButton>
          </>
        ) : (
          <Link to={'/'}>
            <Button variant={'contained'} onClick={handleClose}>
              Close
            </Button>
          </Link>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default MatchPolicyToCoverageModal
