import { yupResolver } from '@hookform/resolvers/yup'
import { Close } from '@mui/icons-material'
import SearchIcon from '@mui/icons-material/Search'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material'
import { NoResultsFound, PhoenixBaseCard } from 'componix'
import { useState } from 'react'
import { Controller, SubmitHandler, set, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { SearchResponseData } from '../../models/SearchResponse'
import { SuggestedMatchModel } from '../../models/WorkItemDetails/SuggestedMatchModel'
import { searchByName } from '../../services/coverageService'
import { addressFormatter } from '../../utils/addressFormatter'
import SuggestedMatchesTable from '../WorkItemDetails/SuggestedMatchesTable'
import PhoenixModalHeader from './PhoenixModalHeader'

interface IFormInput {
  employerName: string
}

interface SearchByEmployerNameModalProps {
  isOpen: boolean
  onCancel: () => void
}

const SearchByEmployerNameModal = ({ isOpen, onCancel }: SearchByEmployerNameModalProps) => {
  const [searchResults, setSearchResults] = useState<SearchResponseData[] | null>(null)
  const [data, setData] = useState<SuggestedMatchModel[]>([])
  const [tooManyResults, setTooManyResults] = useState(false)
  const [isSearching, setIsSearching] = useState(false)
  const [isSearchEnabled, setIsSearchEnabled] = useState(false)
  const min = 1
  const max = 90
  const employerNameSchema = yup.object().shape({
    employerName: yup
      .string()
      .uppercase({ excludeEmptyString: true })
      .matches(/^[a-zA-Z0-9\s]*$/, { excludeEmptyString: true, message: 'Special characters are not allowed.' })
      .min(min, `Employer Name must be at least ${min} characters`)
      .max(max, `Input field must contain no more than ${max} characters.`)
      .required(),
  })

  const { control, handleSubmit, register, reset, formState, trigger, setValue } = useForm({
    defaultValues: {
      employerName: '',
    },
    resolver: yupResolver(employerNameSchema),
  })

  const onNameChange = async (value: string) => {
    if (value.length === 0) return setIsSearchEnabled(false)
    const valid = await trigger('employerName')
    const formattedValue = value.replace(/[^a-zA-Z0-9\s]/g, '').toUpperCase()
    setValue('employerName', formattedValue)
    setIsSearchEnabled(valid)
  }
  const fetchSearchByEmployerName = async (employerName: string) => {
    setIsSearching(true)
    setTooManyResults(false)
    searchByName(employerName, 100)
      .then((response) => {
        if (response.count > 100) {
          setTooManyResults(true)
        }
        setSearchResults(response.data)
        setIsSearching(false)
        mapSearchResults(response.data)
      })
      .catch(() => {
        setIsSearching(false)
      })
  }

  const mapSearchResults = (searchResults: SearchResponseData[]) => {
    if (searchResults.length > 0) {
      const resultData = searchResults.map((result, index) => {
        return {
          suggestionID: index.toString(),
          combo: {
            identifier: result.comboId,
            guid: result.comboGuid,
          },
          coverage: {
            identifier: result.coverageId,
            guid: result.coverageGuid,
          },
          employer: result.employerName,
          fein: result.fein,
          address: addressFormatter(result.primaryAddress) as string,
          open: false,
        }
      })
      setData(resultData)
    }
  }

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    fetchSearchByEmployerName(data.employerName)
  }

  const closeAndClear = () => {
    onCancel()
    reset()
    setData([])
    setTooManyResults(false)
    setSearchResults(null)
    setIsSearching(false)
    setIsSearchEnabled(false)
  }

  return (
    <Dialog fullWidth open={isOpen} maxWidth={'md'} data-testid={'search-by-employer-name-modal'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PhoenixModalHeader title={'Search by Employer Name'} handleClose={closeAndClear} isErrorColor={false} />
        <DialogContent>
          <Controller
            name="employerName"
            control={control}
            render={({ field: { onChange, ...field }, fieldState }) => (
              <TextField
                {...field}
                onChange={(e) => {
                  onChange(e)
                  onNameChange(e.target.value)
                }}
                fullWidth
                label="Employer Name"
                InputLabelProps={{ shrink: true }}
                data-testid="employer-name"
                helperText={fieldState.error?.message}
                error={fieldState.invalid}
                inputProps={{ type: 'search' }}
              />
            )}
          />
        </DialogContent>
        <DialogActions style={{ paddingRight: '24px' }}>
          <Button data-testid="cancel-search-btn" size={'small'} variant="text" onClick={closeAndClear}>
            Cancel
          </Button>
          <LoadingButton
            data-testid="search-btn"
            size={'small'}
            disabled={!isSearchEnabled || isSearching}
            loading={isSearching}
            startIcon={<SearchIcon />}
            loadingIndicator={<CircularProgress size={15} />}
            variant="contained"
            type="submit"
          >
            Search
          </LoadingButton>
        </DialogActions>
      </form>
      {searchResults && !isSearching && (
        <Box padding={'24px'}>
          <PhoenixBaseCard cardTitle="Employer Name Matches">
            {tooManyResults && <NoResultsFound message="Please refine your search criteria and search again." />}

            {!tooManyResults && searchResults != null && <SuggestedMatchesTable matches={data} />}
          </PhoenixBaseCard>
        </Box>
      )}
    </Dialog>
  )
}

export default SearchByEmployerNameModal
