import { Grid } from '@mui/material'
import { FieldGroup, PhoenixBaseCard, TableWithSubrows } from 'componix'
import { useEffect, useState } from 'react'
import React from 'react'
import { useAlertContext } from '../../contexts/AlertContext'
import { blankLossRecordResponse } from '../../models/UnitStatLossRecordsModels'
import { getUnitStatLossRecords } from '../../services/unitStatService'
import { formatMoney } from '../../utils/moneyFormatter'
import { AlertSeverity } from '../AlertNotification/AlertNotification'
import { UnitStatLossRecordsTableColumns } from './UnitStatLossRecordsTable/UnitStatLossRecordsTableColumns'

interface LossRecordsProps {
  uuid: string
}

export const LossRecordsSplit = ({ uuid }: LossRecordsProps) => {
  const [isLoading, setIsLoading] = useState(true)
  const [hasLoaded, setHasLoaded] = useState(false)
  const [lossRecordsData, setLossRecordsData] = useState(blankLossRecordResponse)
  const [error, setError] = React.useState(false)
  const { setDetails: setAlertDetails } = useAlertContext()

  useEffect(() => {
    if (error) {
      setIsLoading(false)
      setAlertDetails({ message: 'Please refresh the page and try again.', severity: AlertSeverity.ERROR })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  const onExpand = async () => {
    setIsLoading(true)
    try {
      const responseData = await getUnitStatLossRecords(uuid)
      if (responseData) {
        setLossRecordsData(responseData)
      }
      setIsLoading(false)
      setHasLoaded(true)
      // eslint-disable-next-line
    } catch (exception) {
      setError(true)
    }
  }

  const handleFirstOpen = () => {
    if (!hasLoaded) {
      onExpand()
    }
  }

  return (
    <div style={{ marginTop: '8px' }}>
      <PhoenixBaseCard collapsible={true} onExpand={handleFirstOpen} cardTitle="Loss Records">
        <TableWithSubrows
          columns={UnitStatLossRecordsTableColumns}
          data={lossRecordsData.records}
          isLoading={isLoading}
          searchable
        />
        <PhoenixBaseCard collapsible={false} variantType="Secondary" cardTitle={'Reported Loss Records Total'}>
          <Grid
            paddingLeft={'8px'}
            paddingTop={'8px'}
            flexDirection={'row'}
            container
            spacing={1}
            marginBottom={'16px'}
          >
            <Grid item sx={{ marginLeft: '16px', marginTop: '16px' }} xs={1.97}>
              <FieldGroup label={'CLAIMS'} value={lossRecordsData.claimsTotal} />
            </Grid>
            <Grid item sx={{ marginTop: '16px' }} xs={1.97}>
              <FieldGroup label={'INCURRED INDEMNITY'} value={formatMoney(lossRecordsData.incurredIndemnityTotal)} />
            </Grid>
            <Grid item sx={{ marginTop: '16px' }} xs={1.97}>
              <FieldGroup label={'INCURRED MEDICAL'} value={formatMoney(lossRecordsData.incurredMedicalTotal)} />
            </Grid>
            <Grid item sx={{ marginTop: '16px' }} xs={1.97}>
              <FieldGroup label={'PAID INDEMNITY'} value={formatMoney(lossRecordsData.paidIndemnityTotal)} />
            </Grid>
            <Grid item sx={{ marginTop: '16px' }} xs={1.97}>
              <FieldGroup label={'PAID MEDICAL'} value={formatMoney(lossRecordsData.paidMedicalTotal)} />
            </Grid>
            <Grid item sx={{ marginTop: '16px' }} xs={1.9}>
              <FieldGroup label={'ALAE PAID'} value={formatMoney(lossRecordsData.alaePaidTotal)} />
            </Grid>
          </Grid>
        </PhoenixBaseCard>
      </PhoenixBaseCard>
    </div>
  )
}

export default LossRecordsSplit
