import { ErrorCommunication } from 'componix'
import { UnitStatErrorResponseModel } from '../UnitStatErrorResponseModel'

export interface USRCarrierError {
  error: UnitStatErrorResponseModel
  communications: ErrorCommunication[]
}

export const BlankCarrierError: USRCarrierError = {
  error: {
    noticeDate: '',
    fineDueDate: '',
    fineStatus: '',
    fineStatusDate: '',
    errors: [],
    guid: '00000000-0000-0000-0000-000000000000',
  },
  communications: [],
}
