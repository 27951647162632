import { CalendarToday, DescriptionOutlined, Person } from '@mui/icons-material'
import { Box, Grid, Skeleton, Stack, Typography } from '@mui/material'
import { FieldGroup, PhoenixBaseCard, StatusBadge } from 'componix'
import { useEffect, useState } from 'react'
import WorkItemDetailsModel from '../../models/WorkItemDetails/WorkItemDetailsModel'

interface WorkItemDetailsCardProps {
  data?: WorkItemDetailsModel | null
  isLoading: boolean
}

export const WorkItemDetailsCard = ({ isLoading, data }: WorkItemDetailsCardProps) => {
  const [workItemStatus, setWorkItemStatus] = useState<string>()
  const statusTypes = ['Open', 'Closed', 'Manually Closed']

  useEffect(() => {
    const status = data?.workItemStatus && statusTypes.find((item) => item.startsWith(data?.workItemStatus))
    setWorkItemStatus(status)
    //eslint-disable-next-line
  }, [data?.workItemStatus])

  /* istanbul ignore next */
  const handleReassign = () => {
    console.log('Reassign')
  }

  /* istanbul ignore next */
  const handleManuallyClose = () => {
    console.log('Close')
  }

  //TODO: Disable Close Button on Unmatched Policy Pages once the backend is set up
  const kebabMenuItems = [
    { label: 'Reassign', to: '#', onClick: handleReassign },
    { label: 'Manually Close', to: '#', onClick: handleManuallyClose },
  ]

  const loadingContent = (
    <Grid sx={{ margin: '16px' }} container direction={'column'} data-testid="loading">
      <Grid item>
        <Skeleton width={'35%'}>
          <StatusBadge description="accepted" />
        </Skeleton>
      </Grid>
      <Grid item>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Skeleton variant="circular" width={30} height={30} sx={{ marginRight: '6px' }} />
          <Skeleton width={'65%'}>
            <FieldGroup label="Skeleton" value="Skeleton" />
          </Skeleton>
        </Box>
      </Grid>
      <Grid item>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Skeleton variant="circular" width={30} height={30} sx={{ marginRight: '6px' }} />
          <Skeleton width={'65%'}>
            <FieldGroup label="Skeleton" value="Skeleton" />
          </Skeleton>
        </Box>
      </Grid>
      <Grid item>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Skeleton variant="circular" width={30} height={30} sx={{ marginRight: '6px' }} />
          <Skeleton width={'65%'}>
            <FieldGroup label="Skeleton" value="Skeleton" />
          </Skeleton>
        </Box>
      </Grid>
      <Grid item>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Skeleton variant="circular" width={30} height={30} sx={{ marginRight: '6px' }} />
          <Skeleton width={'65%'}>
            <FieldGroup label="Skeleton" value="Skeleton" />
          </Skeleton>
        </Box>
      </Grid>
      <Grid item>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Skeleton variant="circular" width={30} height={30} sx={{ marginRight: '6px' }} />
          <Skeleton width={'65%'}>
            <FieldGroup label="Skeleton" value="Skeleton" />
          </Skeleton>
        </Box>
      </Grid>
    </Grid>
  )
  const loadedContent = (
    <Stack sx={{ margin: '16px' }}>
      <Box width={'fit-content'} marginBottom={'8px'}>
        <StatusBadge description={workItemStatus as string} />
      </Box>
      <FieldGroup label={'Work Item Number'} icon={<DescriptionOutlined />} value={data?.workItemNumber} />
      <FieldGroup icon={<Person />} label={'Assigned To'} value={data?.assignedTo} />
      <FieldGroup icon={<Person />} label={'Assigned By'} value={data?.assignedBy} />
      <FieldGroup label={'Assigned Date'} icon={<CalendarToday />} value={data?.assignedDate} />
      <FieldGroup
        label={'Due Date'}
        icon={<CalendarToday />}
        value={data?.dueDate && <Typography {...(data?.isPastDue && { color: 'error' })}>{data?.dueDate}</Typography>}
      />
    </Stack>
  )

  return (
    <Box sx={{ marginTop: '16px' }}>
      <PhoenixBaseCard
        variantType="Primary"
        cardTitle="Work Item Details"
        contentPadded
        defaultWidth={12}
        kebabable={!isLoading && data?.workItemStatus === 'O'}
        kebabMenuItems={kebabMenuItems}
      >
        {isLoading ? loadingContent : loadedContent}
      </PhoenixBaseCard>
    </Box>
  )
}
export default WorkItemDetailsCard
