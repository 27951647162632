import TabList from '@mui/lab/TabList'
import { Box, Button, DialogActions, Tab, Typography } from '@mui/material'
import { PhoenixBaseTable, PhoenixLink } from 'componix'
import { MRT_ColumnDef, MRT_Row } from 'material-react-table'
import { SyntheticEvent } from 'react'
import { WorkQueueItemModel } from '../../models/WorkQueueItemModel'

export const WorkQueueColumns = (isOpen: boolean): MRT_ColumnDef<WorkQueueItemModel>[] => {
  /* eslint react/prop-types: 0 */
  const lastColumn = isOpen
    ? {
        accessorKey: 'dueDate',
        header: 'DUE DATE',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        Cell: ({ row, renderedCellValue }) => {
          return (
            <Typography {...(row.original.isPastDue && { color: 'error' })} fontSize={14}>
              <>{renderedCellValue}</>
            </Typography>
          )
        },
      }
    : {
        accessorKey: 'closedDate',
        header: 'CLOSED DATE',
      }

  return [
    {
      accessorKey: 'workItemNumber',
      header: 'WORK ITEM NUMBER',
      Cell: ({ row }) => {
        const { entityType, workItemID, workItemNumber } = row.original
        // this will most likely need to be a switch statement in the future
        const workItemPath = entityType === 'WCPOL_Transactions' ? `unmatched-policies` : `carrier-policy-errors`

        return <PhoenixLink to={`workQueue/${workItemPath}/${workItemID}`} value={workItemNumber} />
      },
    },
    {
      accessorKey: 'entityType',
      header: 'WORK TYPE',
    },
    {
      accessorKey: 'assignedBy',
      header: 'ASSIGNED BY',
    },
    {
      accessorKey: 'assignedDate',
      header: 'ASSIGNED DATE',
    },
    lastColumn,
  ]
}

interface WorkQueueTableProps {
  data: WorkQueueItemModel[]
  isLoading: boolean
  handleTabChange: (_: SyntheticEvent, newValue: string) => void
  isOpenTable: boolean
}

const WorkQueueTable = ({ data, isLoading, handleTabChange, isOpenTable }: WorkQueueTableProps) => {
  return (
    <PhoenixBaseTable
      columns={WorkQueueColumns(isOpenTable)}
      data={data}
      isLoading={isLoading}
      showTableWithoutData
      enableRowSelection
      showPagination
      overrides={{
        ...(isOpenTable && { enableRowSelection: true }),
        enableGlobalFilter: true,
        enableFilters: true,
        filterFromLeafRows: true,
        enableTopToolbar: true,
        initialState: {
          showGlobalFilter: true,
          showColumnFilters: false,
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        renderTopToolbar: ({ table }: any) => {
          const handleReassign = () => {
            const rows: string[] = []
            table.getSelectedRowModel().flatRows.forEach((row: MRT_Row<WorkQueueItemModel>) => {
              rows.push(row.original.workItemID)
            })
            // Insert reassign button functionality
          }

          const handleClose = () => {
            const rows: string[] = []
            table.getSelectedRowModel().flatRows.forEach((row: MRT_Row<WorkQueueItemModel>) => {
              rows.push(row.original.workItemID)
            })
            // Insert close button functionality
          }

          const noRowsSelected = !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          return (
            <Box display="flex" justifyContent="space-between">
              <>
                {/* TabList should be a sibling of TabPanels, but down here to override styling and match designs */}
                <TabList onChange={handleTabChange}>
                  <Tab label={'Open'} value={'0'}></Tab>
                  <Tab label={'Closed'} value={'1'}></Tab>
                </TabList>
              </>
              {isOpenTable && (
                <>
                  <DialogActions>
                    <Button size="small" variant="contained" disabled={noRowsSelected} onClick={handleReassign}>
                      Reassign
                    </Button>
                    <Button size="small" variant="contained" disabled={noRowsSelected} onClick={handleClose}>
                      Cancel
                    </Button>
                  </DialogActions>
                </>
              )}
            </Box>
          )
        },
      }}
    />
  )
}

export default WorkQueueTable
