import { UUID } from 'crypto'

export interface WorkItemDetailsModel {
  workQueue_WorkItemID: string
  entityType: string
  workItemNumber: string
  workItemDescription: string
  workItemStatus: string
  assignedDate: string
  dueDate: string
  assignedBy: string
  assignedTo: string
  isPastDue: boolean
  associatedEntities: {
    policyId?: UUID | null
    comboId?: string | null
    unitStatId?: UUID | null
  }
}

export const BlankWorkItemDetails: WorkItemDetailsModel = {
  workQueue_WorkItemID: '',
  entityType: '',
  workItemNumber: '',
  workItemDescription: '',
  workItemStatus: '',
  assignedDate: '',
  dueDate: '',
  assignedBy: '',
  assignedTo: '',
  isPastDue: false,
  associatedEntities: {
    policyId: null,
    comboId: null,
    unitStatId: null,
  },
}

export default WorkItemDetailsModel
