import { BreadcrumbBar } from 'componix'
import { UUID } from 'crypto'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { AlertSeverity } from '../components/AlertNotification/AlertNotification'
import PolicyInfoGutterCard from '../components/ClaimDetails/PolicyInfoGutterCard'
import UnitStatInfoGutterCard from '../components/ClaimDetails/UnitStatInfoGutterCard'
import LayoutWithSideRail from '../components/LayoutWithSideRail'
import { USRCarrierErrorNotification } from '../components/WorkItemDetails/USRCarrierErrorNotification'
import WorkItemDetailsCard from '../components/WorkItemDetails/WorkItemDetailsCard'
import { useAlertContext } from '../contexts/AlertContext'
import { useNavbar } from '../contexts/NavbarContext'
import {
  EmptyPolicyDetailsResponse,
  PolicyDetailsResponseModel,
} from '../models/PolicyDetails/PolicyDetailsResponseModel'
import UnitStatDetailsModel, { blankUnitStatDetailsModel } from '../models/UnitStatDetailsModel'
import WorkItemDetailsModel, { BlankWorkItemDetails } from '../models/WorkItemDetails/WorkItemDetailsModel'
import { getPolicySummaryDetails } from '../services/policyService'
import { getUnitStatDetails } from '../services/unitStatService'
import { getWorkItemDetails } from '../services/workItemService'

export const CarrierErrorNotifications = () => {
  const { workItemId } = useParams()
  const { marginLeft } = useNavbar()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isLoadingPolicyData, setIsLoadingPolicyData] = useState<boolean>(true)
  const [isLoadingUnitStatData, setIsLoadingUnitStatData] = useState<boolean>(true)
  const [detailsData, setDetailsData] = useState<WorkItemDetailsModel>(BlankWorkItemDetails)
  const [policyId, setPolicyId] = useState<UUID | null>(null)
  const [unitStatId, setUnitStatId] = useState<UUID | null>(null)
  const [policyData, setPolicyData] = useState<PolicyDetailsResponseModel>(
    EmptyPolicyDetailsResponse as PolicyDetailsResponseModel
  )
  const [unitStatData, setUnitStatData] = useState<UnitStatDetailsModel>(
    blankUnitStatDetailsModel as UnitStatDetailsModel
  )
  const { setDetails } = useAlertContext()

  /* istanbul ignore next */
  const fetchWorkItemDetails = async () => {
    getWorkItemDetails(workItemId as UUID)
      .then((data: WorkItemDetailsModel) => {
        if (data) {
          setDetailsData(data)
          if (data?.associatedEntities?.policyId) {
            setPolicyId(data.associatedEntities.policyId)
          }
          if (data?.associatedEntities?.unitStatId) {
            setUnitStatId(data.associatedEntities.unitStatId)
          }
        }
      })
      .catch(() => {
        setDetails({ message: 'Please refresh the page and try again.', severity: AlertSeverity.ERROR })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  /* istanbul ignore next */
  const fetchPolicyDetails = async () => {
    getPolicySummaryDetails(policyId as UUID)
      .then((data: PolicyDetailsResponseModel) => {
        if (data) {
          setPolicyData(data)
        }
      })
      .catch(() => {
        setDetails({ message: 'Please refresh the page and try again.', severity: AlertSeverity.ERROR })
      })
      .finally(() => {
        setIsLoadingPolicyData(false)
      })
  }

  /* istanbul ignore next */
  const fetchUnitStatDetails = async () => {
    getUnitStatDetails(unitStatId as UUID)
      .then((data: UnitStatDetailsModel) => {
        if (data) {
          setUnitStatData(data)
        }
      })
      .catch(() => {
        setDetails({ message: 'Please refresh the page and try again.', severity: AlertSeverity.ERROR })
      })
      .finally(() => {
        setIsLoadingUnitStatData(false)
      })
  }

  /* istanbul ignore next */
  useEffect(() => {
    fetchWorkItemDetails()
    //eslint-disable-next-line
  }, [workItemId])

  /* istanbul ignore next */
  useEffect(() => {
    if (policyId) {
      fetchPolicyDetails()
    }
    //eslint-disable-next-line
  }, [policyId])

  useEffect(() => {
    if (unitStatId) {
      fetchUnitStatDetails()
    }
    //eslint-disable-next-line
  }, [unitStatId])

  return (
    <div
      style={{
        transition: 'margin-left 0.2s',
        marginLeft: marginLeft,
        marginRight: '24px',
        marginTop: '80px',
      }}
    >
      <BreadcrumbBar
        crumbs={[
          {
            label: `Work Queue`,
            to: `/`,
          },
          {
            label: detailsData ? `Work Item ${detailsData?.workItemNumber}` : 'undefined',
            to: `/workqueue/carrier-policy-errors/${workItemId}`,
          },
        ]}
      />
      <LayoutWithSideRail
        mainContent={<USRCarrierErrorNotification workItem={detailsData} />}
        sideRailComponentArray={[
          <WorkItemDetailsCard key={'wid'} data={detailsData} isLoading={isLoading} />,
          policyId ? (
            <PolicyInfoGutterCard
              key="pigc"
              policy={policyData}
              guid={policyId as UUID}
              isLoading={isLoadingPolicyData}
            />
          ) : (
            <></>
          ),
          unitStatId ? (
            <UnitStatInfoGutterCard
              key="usigc"
              unitStat={unitStatData}
              uuid={unitStatId as UUID}
              isLoading={isLoadingUnitStatData}
            />
          ) : (
            <></>
          ),
        ]}
      />
    </div>
  )
}

export default CarrierErrorNotifications
