import { Box, Button } from '@mui/material'
import { PhoenixBaseTable } from 'componix'
import { DataFieldBlankValue, PhoenixLink } from 'componix'
import { MRT_ColumnDef } from 'material-react-table'
import { SuggestedMatchModel } from '../../models/WorkItemDetails/SuggestedMatchModel'

const SuggestedMatchesColumns: MRT_ColumnDef<SuggestedMatchModel>[] = [
  {
    accessorKey: 'combo.identifier',
    header: 'Combo ID',
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    Cell: ({ cell, row }) => (
      <>
        {row.original.combo.guid ? (
          <PhoenixLink
            id={`Combo-${row.index}`}
            to={`/combos/${row.original.combo.guid}`}
            value={cell.getValue<string>()}
          />
        ) : (
          <DataFieldBlankValue />
        )}
      </>
    ),
  },
  {
    accessorKey: 'coverage.identifier',
    header: 'Coverage ID',
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    Cell: ({ cell, row }) => (
      <>
        {cell.getValue() ? (
          <PhoenixLink
            id={`Coverage-${row.index}`}
            to={`/coverages/${row.original.coverage.identifier}`}
            value={cell.getValue<string>()}
          />
        ) : (
          <DataFieldBlankValue />
        )}
      </>
    ),
  },
  {
    accessorKey: 'employer',
    header: 'Employer',
  },
  {
    accessorKey: 'address',
    header: 'Address',
  },
  {
    accessorKey: 'fein',
    header: 'FEIN',
  },
]

interface SuggestedMatchesTableProps {
  matches: SuggestedMatchModel[]
}

const SuggestedMatchesTable = ({ matches }: SuggestedMatchesTableProps) => {
  return (
    <PhoenixBaseTable
      columns={SuggestedMatchesColumns}
      data={matches}
      overrides={{
        enableRowActions: true,
        positionActionsColumn: 'last',
        renderRowActions: () => (
          <Box display={'flex'} justifyContent={'flex-end'} pr={'4px'}>
            <Button variant="contained" color="primary" size="small" onClick={() => console.log('Clicked Match!')}>
              Match
            </Button>
          </Box>
        ),
      }}
    />
  )
}

export default SuggestedMatchesTable
