import { Box, Button, Grid } from '@mui/material'
import {
  ErrorAttachment,
  ErrorDTO,
  FieldGroup,
  PhoenixBaseCard,
  PhoenixBaseTable,
  ResponseTimeline,
  StatusBadge,
} from 'componix'
import { MRT_ColumnDef } from 'material-react-table'
import { useEffect, useState } from 'react'
import useAttachmentsModal from '../../hooks/useAttachmentsModal'
import useErrorProcessingModal from '../../hooks/useErrorProcessingModal'
import { UpdateErrorsModel } from '../../models/PolicyDetails/UpdateErrorsModel'
import { BlankCarrierError, USRCarrierError } from '../../models/WorkItemDetails/USRCarrierError'
import WorkItemDetailsModel from '../../models/WorkItemDetails/WorkItemDetailsModel'
import { getUSRCarrierErrorNotifications, resolveUSRCarrierError } from '../../services/workItemService'
import AcceptErrorModal from '../Modals/AcceptErrorModal'
import RejectErrorModal from '../Modals/RejectErrorModal'
import AttachmentsModal from '../PolicyErrors/AttachmentsModal'

export const CarrierErrorColumns: MRT_ColumnDef<ErrorDTO>[] = [
  /* eslint react/prop-types: 0 */
  {
    accessorKey: 'errorMessage',
    header: 'Error Message',
    minSize: 240,
  },
  {
    accessorKey: 'editID',
    header: 'EDIT ID',
  },
  {
    accessorKey: 'status',
    header: 'STATUS',
    Cell: ({ cell }) => <StatusBadge description={cell.getValue<string>()} />,
    size: 10,
  },
]

interface CarrierErrorNotificationsProps {
  workItem: WorkItemDetailsModel
}

export const USRCarrierErrorNotification = ({ workItem }: CarrierErrorNotificationsProps) => {
  const [carrierError, setCarrierError] = useState<USRCarrierError>(BlankCarrierError)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const {
    isModalOpen: isAttachmentsModalOpen,
    openModal: openAttachmentsModal,
    closeModal: closeAttachmentsModal,
    currentAttachments: attachments,
  } = useAttachmentsModal()

  const fetchCarrierErrors = async () => {
    getUSRCarrierErrorNotifications(workItem.workQueue_WorkItemID)
      .then((data) => {
        setCarrierError(data)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }
  const updateUSRCarrierErrors = async (request: UpdateErrorsModel) => {
    return resolveUSRCarrierError(request.userAccepted, workItem.workQueue_WorkItemID, request.responseText)
  }

  const {
    isModalOpen: isRejectModalOpen,
    openModal: openRejectModal,
    closeModal: closeRejectModal,
    rejectErrors,
  } = useErrorProcessingModal(fetchCarrierErrors, updateUSRCarrierErrors)

  const {
    isModalOpen: isAcceptModalOpen,
    openModal: openAcceptModal,
    closeModal: closeAcceptModal,
    acceptErrors,
  } = useErrorProcessingModal(fetchCarrierErrors, updateUSRCarrierErrors)

  useEffect(() => {
    if (workItem.workQueue_WorkItemID?.length > 0) {
      fetchCarrierErrors()
    }
  }, [workItem.workQueue_WorkItemID])
  const error = carrierError!.error
  return (
    <PhoenixBaseCard contentPadded cardTitle="Carrier Error Notification">
      <PhoenixBaseCard isLoading={isLoading} cardTitle="USR Error Details" variantType="Secondary">
        <Grid container padding={'8px'}>
          <Grid item xs={3}>
            <FieldGroup label="Notice Date" value={error.noticeDate}></FieldGroup>
          </Grid>
          <Grid item xs={3}>
            <FieldGroup label="Fine Due Date" value={error.fineDueDate}></FieldGroup>
          </Grid>
          <Grid item xs={3}>
            <FieldGroup label="Fine Status" value={error.fineStatus}></FieldGroup>
          </Grid>
          <Grid item xs={3}>
            <FieldGroup label="Fine Status Date" value={error.fineStatusDate}></FieldGroup>
          </Grid>
        </Grid>
        <PhoenixBaseTable columns={CarrierErrorColumns} data={error.errors} />
        <Grid container>
          <Grid item xs={9}>
            <ResponseTimeline
              attachmentsFunc={function (rows: ErrorAttachment[]): void {
                openAttachmentsModal(rows)
              }}
              responses={carrierError!.communications}
            />
          </Grid>
          <Grid item xs={3}>
            {workItem.workItemStatus == 'Open' && (
              <Box sx={{ display: 'flex', gap: '0.5rem', mt: '22px' }}>
                <Button color="success" onClick={() => openAcceptModal([error.errors[0].editID])} variant="contained">
                  Accept
                </Button>
                <Button color="error" onClick={() => openRejectModal([error.errors[0].editID])} variant="contained">
                  Reject
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
      </PhoenixBaseCard>

      <AcceptErrorModal
        isOpen={isAcceptModalOpen}
        onCancel={closeAcceptModal}
        onAccept={() => acceptErrors(workItem.workQueue_WorkItemID, 'unitstat')}
      />
      <RejectErrorModal
        isOpen={isRejectModalOpen}
        onCancel={closeRejectModal}
        onReject={(responseText: string) => rejectErrors(workItem.workQueue_WorkItemID, 'unitstat', responseText)}
      />
      <AttachmentsModal isOpen={isAttachmentsModalOpen} onCancel={closeAttachmentsModal} attachments={attachments} />
    </PhoenixBaseCard>
  )
}
