import { Skeleton } from '@mui/material'
import { NoResultsFound, PhoenixBaseCard } from 'componix'
import { UUID } from 'crypto'
import { useState } from 'react'
import useAttachmentsModal from '../../hooks/useAttachmentsModal'
import useErrorProcessingModal from '../../hooks/useErrorProcessingModal'
import { PolicyErrorResponseModel } from '../../models/PolicyDetails/PolicyErrorResponseModel'
import { UpdateErrorsModel } from '../../models/PolicyDetails/UpdateErrorsModel'
import { getPolicyErrors, updatePolicyErrors } from '../../services/policyService'
import AcceptErrorModal from '../Modals/AcceptErrorModal'
import RejectErrorModal from '../Modals/RejectErrorModal'
import AttachmentsModal from './AttachmentsModal'
import { TransactionCard } from './TransactionCard'

export interface PolicyErrorProps {
  policyID: UUID
}

const PolicyErrors = ({ policyID }: PolicyErrorProps) => {
  const [opened, setOpened] = useState(false)
  const [policyErrors, setPolicyErrors] = useState<PolicyErrorResponseModel[]>([])
  const [isLoading, setIsLoading] = useState(true)

  const fetchPolicyErrors = async () => {
    setIsLoading(true)
    getPolicyErrors(policyID)
      .then((data) => {
        setPolicyErrors(data)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const updateErrors = async (request: UpdateErrorsModel) => {
    return updatePolicyErrors(policyID, request)
  }

  const {
    isModalOpen: isAcceptModalOpen,
    openModal: openAcceptModal,
    closeModal: closeAcceptModal,
    acceptErrors,
    isCallLoading: isAcceptCallLoading,
  } = useErrorProcessingModal(fetchPolicyErrors, updateErrors)

  const {
    isModalOpen: isRejectModalOpen,
    openModal: openRejectModal,
    closeModal: closeRejectModal,
    rejectErrors,
    isCallLoading: isRejectCallLoading,
  } = useErrorProcessingModal(fetchPolicyErrors, updateErrors)

  const {
    isModalOpen: isAttachmentsModalOpen,
    openModal: openAttachmentsModal,
    closeModal: closeAttachmentsModal,
    currentAttachments: attachments,
  } = useAttachmentsModal()

  const isTransactionLoading = isLoading || isAcceptCallLoading || isRejectCallLoading

  const renderContent = () => {
    return policyErrors.length ? (
      policyErrors.map((error) => (
        <TransactionCard
          key={error.guid}
          error={error}
          isLoading={isTransactionLoading}
          openAcceptModal={openAcceptModal}
          openRejectModal={openRejectModal}
          openAttachments={openAttachmentsModal}
        />
      ))
    ) : (
      <NoResultsFound />
    )
  }

  return (
    <>
      <PhoenixBaseCard
        cardTitle={'Policy Errors'}
        contentPadded
        collapsible
        onExpand={async () => {
          if (opened) return
          setOpened(true)
          await fetchPolicyErrors()
        }}
      >
        {isLoading ? (
          <>
            <Skeleton width={'100%'} />
            <Skeleton width={'100%'} />
            <Skeleton width={'100%'} />
          </>
        ) : (
          renderContent()
        )}
      </PhoenixBaseCard>
      <AcceptErrorModal
        isOpen={isAcceptModalOpen}
        onCancel={closeAcceptModal}
        onAccept={() => acceptErrors(policyID, 'policy')}
      />
      <RejectErrorModal
        isOpen={isRejectModalOpen}
        onCancel={closeRejectModal}
        onReject={(responseText: string) => rejectErrors(policyID, 'policy', responseText)}
      />
      <AttachmentsModal isOpen={isAttachmentsModalOpen} onCancel={closeAttachmentsModal} attachments={attachments} />
    </>
  )
}

export default PolicyErrors
