import { Policy } from '../models/Policy'
import PolicyDetailsCancellationsReintstatementsModel from '../models/PolicyDetails/PolicyDetailsCancellationsReintstatementsModel'
import PolicyEmployersLocationsModel from '../models/PolicyDetails/PolicyDetailsEmployerLocations'
import PolicyDetailsEndorsementsModel from '../models/PolicyDetails/PolicyDetailsEndorsementsModel'
import PolicyDetailsPremiumModel from '../models/PolicyDetails/PolicyDetailsPremiumModel'
import {
  EmptyPolicyDetailsPrimaryModel,
  PolicyDetailsPrimaryModel,
} from '../models/PolicyDetails/PolicyDetailsPrimaryModel'
import {
  EmptyPolicyDetailsResponse,
  PolicyDetailsResponseModel,
} from '../models/PolicyDetails/PolicyDetailsResponseModel'
import { PolicyErrorResponseModel } from '../models/PolicyDetails/PolicyErrorResponseModel'
import { UpdateErrorsModel } from '../models/PolicyDetails/UpdateErrorsModel'
import UnitStatModel from '../models/UnitStatModel'
import api, { ApiResponse } from '../utils/apiService'
import { Endpoints } from '../utils/endpoint'

export const getPolicyListByCoverageId = async (coverageId: string, expanded: boolean = true) => {
  const response: ApiResponse<Policy[]> = await api.get(
    `${Endpoints.PolicySearchByCoverageId}/${coverageId}/policies?expanded=${expanded}`
  )
  return response.data
}

export const getPolicySummaryDetails = async (policyId: string): Promise<PolicyDetailsResponseModel> => {
  const response = await api.get(`${Endpoints.Policy}/${policyId}`)
  if (response.status === 204) {
    Promise.resolve(EmptyPolicyDetailsResponse)
  }
  return Promise.resolve(response.data)
}

export const getPolicyPrimaryName = async (policyId: string): Promise<PolicyDetailsPrimaryModel> => {
  const response = await api.get(`${Endpoints.Policy}/${policyId}/primary`)
  if (response.status === 204) {
    Promise.resolve(EmptyPolicyDetailsPrimaryModel)
  }
  return response.data
}

export const getPolicyPremiums = async (policyId: string) => {
  const response: ApiResponse<PolicyDetailsPremiumModel[]> = await api.get(`${Endpoints.Policy}/${policyId}/premiums`)
  return response.data
}

export const getPolicyEndorsements = async (policyId: string) => {
  const response: ApiResponse<PolicyDetailsEndorsementsModel[]> = await api.get(
    `${Endpoints.Policy}/${policyId}/endorsements`
  )
  return response.data
}
export const getPolicyDetailExposure = async (policyId: string) => {
  return await api.get(`${Endpoints.Policy}/${policyId}/exposures`)
}

export const getPolicyEmployersLocations = async (policyId: string) => {
  const response: ApiResponse<PolicyEmployersLocationsModel[]> = await api.get(
    `${Endpoints.Policy}/${policyId}/locations`
  )
  return response.data
}

export const getPolicyCancellationsReinstatements = async (policyId: string) => {
  const response: ApiResponse<PolicyDetailsCancellationsReintstatementsModel[]> = await api.get(
    `${Endpoints.Policy}/${policyId}/cancellations`
  )
  return response.data
}

export const getPolicyUnitStats = async (policyId: string) => {
  const response: ApiResponse<UnitStatModel[]> = await api.get(`${Endpoints.Policy}/${policyId}/unitstats`)
  return response.data
}

export const getPolicyErrors = async (policyId: string): Promise<PolicyErrorResponseModel[]> => {
  const response: ApiResponse<PolicyErrorResponseModel[]> = await api.get(`${Endpoints.Policy}/${policyId}/errors`)
  return response.data
}

export const updatePolicyErrors = async (policyId: string, body: UpdateErrorsModel) => {
  const response: ApiResponse<number> = await api.post(`${Endpoints.Policy}/${policyId}/errors`, {
    editIDs: body.editIDs,
    userAccepted: body.userAccepted,
    responseText: body.responseText,
  })
  return response.data
}

export const searchPolicyByPolicyNumber = async (policyNumber: string) => {
  const response = await api.get(`${Endpoints.Policy}/SearchByPolicyNumber/${policyNumber}`)
  return response.data
}
