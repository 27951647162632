import { useQuery } from '@tanstack/react-query'
import { BreadcrumbBar } from 'componix'
import { UUID } from 'crypto'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { AlertSeverity } from '../components/AlertNotification/AlertNotification'
import LayoutWithSideRail from '../components/LayoutWithSideRail'
import SuggestedMatches from '../components/WorkItemDetails/SuggestedMatches'
import UnmatchedTransaction from '../components/WorkItemDetails/UnmatchedTransaction'
import WorkItemDetailsCard from '../components/WorkItemDetails/WorkItemDetailsCard'
import { CacheKeys } from '../constants/cacheKeys'
import { useAlertContext } from '../contexts/AlertContext'
import { useNavbar } from '../contexts/NavbarContext'
import { UnmatchedTransactionModel } from '../models/WorkItemDetails/UnmatchedTransactionModel'
import WorkItemDetailsModel from '../models/WorkItemDetails/WorkItemDetailsModel'
import { getUnmatchedTransaction, getWorkItemDetails } from '../services/workItemService'

export const UnmatchedPolicies = () => {
  const { workItemId } = useParams()
  const { marginLeft } = useNavbar()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [detailsData, setDetailsData] = useState<WorkItemDetailsModel | null>(null)
  const { setDetails } = useAlertContext()

  /* istanbul ignore next */
  const fetchWorkItemDetails = async () => {
    getWorkItemDetails(workItemId as UUID)
      .then((data: WorkItemDetailsModel) => {
        if (data) {
          setDetailsData(data)
        }
      })
      .catch(() => {
        setDetails({ message: 'Please refresh the page and try again.', severity: AlertSeverity.ERROR })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const {
    isPending: isUnmatchedTransactionLoading,
    error: unmatchedTransactionError,
    data: unmatchedTransaction,
  } = useQuery<UnmatchedTransactionModel>({
    queryKey: [CacheKeys.UnmatchedTransaction, workItemId],
    queryFn: () => getUnmatchedTransaction(workItemId as UUID),
    enabled: !!workItemId,
  })

  useEffect(() => {
    if (unmatchedTransactionError) {
      setDetails({ message: 'Please refresh the page and try again.', severity: AlertSeverity.ERROR })
    }
  }, [unmatchedTransactionError])

  /* istanbul ignore next */
  useEffect(() => {
    fetchWorkItemDetails()
    //eslint-disable-next-line
  }, [workItemId])

  return (
    <div
      style={{
        transition: 'margin-left 0.2s',
        marginLeft: marginLeft,
        marginRight: '24px',
        marginTop: '80px',
      }}
    >
      <BreadcrumbBar
        crumbs={[
          {
            label: `Work Queue`,
            to: `/`,
          },
          {
            label: detailsData ? `Work Item ${detailsData?.workItemNumber}` : 'undefined',
            to: `/workqueue/unmatched-policies/${workItemId}`,
          },
        ]}
      />
      <LayoutWithSideRail
        mainContent={
          <>
            <UnmatchedTransaction
              unmatchedTransaction={unmatchedTransaction}
              isLoading={isUnmatchedTransactionLoading}
            />
            <SuggestedMatches unmatchedTransaction={unmatchedTransaction} uuid={workItemId as string} />
          </>
        }
        sideRailComponentArray={[<WorkItemDetailsCard key={'wid'} data={detailsData} isLoading={isLoading} />]}
      />
    </div>
  )
}

export default UnmatchedPolicies
