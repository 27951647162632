import { Box, Button } from '@mui/material'
import { DataFieldBlankValue, PhoenixBaseCard, PhoenixBaseTable, PhoenixLink } from 'componix'
import { MRT_ColumnDef } from 'material-react-table'
import { useEffect, useState } from 'react'
import { useAlertContext } from '../../contexts/AlertContext'
import { useMatchPolicyToCoverage } from '../../hooks/workQueue/useMatchPolicyToCoverage'
import { SuggestedMatchModel } from '../../models/WorkItemDetails/SuggestedMatchModel'
import { UnmatchedTransactionModel } from '../../models/WorkItemDetails/UnmatchedTransactionModel'
import { getSuggestedMatches } from '../../services/workItemService'
import { AlertSeverity } from '../AlertNotification/AlertNotification'
import SearchByEmployerNameModal from '../Modals/SearchByEmployerNameModal'
import MatchPolicyToCoverageModal from './MatchPolicyToCoverageModal'

/* eslint react/prop-types: 0 */
const SuggestedMatchesColumns: MRT_ColumnDef<SuggestedMatchModel>[] = [
  {
    accessorKey: 'combo.identifier',
    header: 'Combo ID',
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    Cell: ({ cell, row }) => (
      <>
        {row.original.combo.guid ? (
          <PhoenixLink
            id={`Combo-${row.index}`}
            to={`/combos/${row.original.combo.guid}`}
            value={cell.getValue<string>()}
          />
        ) : (
          <DataFieldBlankValue />
        )}
      </>
    ),
  },
  {
    accessorKey: 'coverage.identifier',
    header: 'Coverage ID',
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    Cell: ({ cell, row }) => (
      <>
        {cell.getValue() ? (
          <PhoenixLink
            id={`Coverage-${row.index}`}
            to={`/coverages/${row.original.coverage.identifier}`}
            value={cell.getValue<string>()}
          />
        ) : (
          <DataFieldBlankValue />
        )}
      </>
    ),
  },
  {
    accessorKey: 'employer',
    header: 'Employer',
  },
  {
    accessorKey: 'address',
    header: 'Address',
  },
  {
    accessorKey: 'fein',
    header: 'FEIN',
  },
]

interface SuggestedMatchesProps {
  unmatchedTransaction: UnmatchedTransactionModel | undefined
  uuid: string
}

const SuggestedMatches = ({ uuid, unmatchedTransaction }: SuggestedMatchesProps) => {
  const [openSearchByEmployerNameModal, setOpenSearchByEmployerNameModal] = useState(false)
  const { isModalOpen, openModal, closeModal, match, activeStep, handleConfirmMatch, isMatchLoading, setFoundMatch } =
    useMatchPolicyToCoverage()
  const [matches, setMatches] = useState<SuggestedMatchModel[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const { setDetails } = useAlertContext()

  const fetchSuggestedMatches = async () => {
    getSuggestedMatches(uuid)
      .then((data: SuggestedMatchModel[]) => {
        if (data) {
          setMatches(data)
        }
      })
      .catch(() => {
        setDetails({ message: 'Please refresh the page and try again.', severity: AlertSeverity.ERROR })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    fetchSuggestedMatches()
    //eslint-disable-next-line
  }, [])

  return (
    <>
      <Box mt={2}>
        <PhoenixBaseCard
          cardTitle="Suggested Matches"
          isLoading={isLoading}
          kebabable
          kebabMenuItems={[
            {
              label: 'Search by Employer Name',
              to: '',
              onClick: () => setOpenSearchByEmployerNameModal(true),
            },
          ]}
        >
          <PhoenixBaseTable
            columns={SuggestedMatchesColumns}
            data={matches}
            overrides={{
              enableRowActions: true,
              positionActionsColumn: 'last',
              // eslint-disable-next-line
              // @ts-ignore
              renderRowActions: ({ row }) => (
                <Box display={'flex'} justifyContent={'flex-end'} pr={'4px'}>
                  <Button variant="contained" color="primary" size="small" onClick={() => openModal(row.original)}>
                    Match
                  </Button>
                </Box>
              ),
            }}
          />
        </PhoenixBaseCard>
        <MatchPolicyToCoverageModal
          data={unmatchedTransaction}
          isOpen={isModalOpen}
          handleClose={closeModal}
          setFoundMatch={setFoundMatch}
          match={match}
          isMatchLoading={isMatchLoading}
          activeStep={activeStep}
          handleConfirmMatch={handleConfirmMatch}
        />
      </Box>
      <SearchByEmployerNameModal
        isOpen={openSearchByEmployerNameModal}
        onCancel={() => setOpenSearchByEmployerNameModal(false)}
      />
    </>
  )
}

export default SuggestedMatches
