import { Grid } from '@mui/material'
import { ErrorTrackingTable, ErrorTrackingTableColumns, FieldGroup, NoResultsFound, PhoenixBaseCard } from 'componix'
import { useState } from 'react'
import { useAlertContext } from '../../contexts/AlertContext'
import useAttachmentsModal from '../../hooks/useAttachmentsModal'
import useErrorProcessingModal from '../../hooks/useErrorProcessingModal'
import { PolicyErrorResponseModel } from '../../models/PolicyDetails/PolicyErrorResponseModel'
import { UpdateErrorsModel } from '../../models/PolicyDetails/UpdateErrorsModel'
import { getUnitStatErrors, updateUnitStatErrors } from '../../services/unitStatService'
import { AlertSeverity } from '../AlertNotification/AlertNotification'
import AcceptErrorModal from '../Modals/AcceptErrorModal'
import RejectErrorModal from '../Modals/RejectErrorModal'
import AttachmentsModal from '../PolicyErrors/AttachmentsModal'

interface UnitStatReportsErrorsProps {
  uuid: string
}

const UnitStatReportsErrors = ({ uuid }: UnitStatReportsErrorsProps) => {
  const [isGetLoading, setIsGetLoading] = useState(false)
  const [errorData, setErrorData] = useState<PolicyErrorResponseModel | null>(null)
  const { setDetails } = useAlertContext()

  const fetchErrors = async () => {
    getUnitStatErrors(uuid)
      .then((data) => {
        setErrorData(data)
      })
      .catch(() => {
        setDetails({ message: 'Please refresh the page and try again.', severity: AlertSeverity.ERROR })
      })
      .finally(() => {
        setIsGetLoading(false)
      })
  }

  const updateErrors = async (request: UpdateErrorsModel) => {
    return updateUnitStatErrors(uuid, request)
  }

  const {
    isModalOpen: isAcceptModalOpen,
    openModal: openAcceptModal,
    closeModal: closeAcceptModal,
    acceptErrors,
    isCallLoading: isAcceptCallLoading,
  } = useErrorProcessingModal(fetchErrors, updateErrors)

  const {
    isModalOpen: isRejectModalOpen,
    openModal: openRejectModal,
    closeModal: closeRejectModal,
    rejectErrors,
    isCallLoading: isRejectCallLoading,
  } = useErrorProcessingModal(fetchErrors, updateErrors)

  const {
    isModalOpen: isAttachmentsModalOpen,
    openModal: openAttachmentsModal,
    closeModal: closeAttachmentsModal,
    currentAttachments: attachments,
  } = useAttachmentsModal()

  const onExpand = () => {
    setIsGetLoading(true)
    fetchErrors()
  }

  const handleFirstOpen = () => {
    if (!errorData) {
      onExpand()
    }
  }

  const isLoading = isGetLoading || isAcceptCallLoading || isRejectCallLoading

  return (
    <div style={{ marginTop: '8px' }}>
      <PhoenixBaseCard cardTitle="USR Errors" onExpand={handleFirstOpen} collapsible={true} isLoading={isLoading}>
        {errorData ? (
          <>
            <Grid container padding={'8px 16px'}>
              <Grid item xs={3}>
                <FieldGroup label="Notice Date" value={errorData?.noticeDate}></FieldGroup>
              </Grid>
              <Grid item xs={3}>
                <FieldGroup label="Fine Due Date" value={errorData?.fineDueDate}></FieldGroup>
              </Grid>
              <Grid item xs={3}>
                <FieldGroup label="Fine Status" value={errorData?.fineStatus}></FieldGroup>
              </Grid>
              <Grid item xs={3}>
                <FieldGroup label="Fine Status Date" value={errorData?.fineStatusDate}></FieldGroup>
              </Grid>
            </Grid>
            <ErrorTrackingTable
              data={errorData.errors}
              acceptFunc={openAcceptModal}
              rejectFunc={openRejectModal}
              attachmentsFunc={openAttachmentsModal}
              columns={ErrorTrackingTableColumns}
            />
            <AcceptErrorModal
              isOpen={isAcceptModalOpen}
              onCancel={closeAcceptModal}
              onAccept={() => acceptErrors(uuid, 'unitstat')}
            />
            <RejectErrorModal
              isOpen={isRejectModalOpen}
              onCancel={closeRejectModal}
              onReject={(responseText: string) => rejectErrors(uuid, 'unitstat', responseText)}
            />
            <AttachmentsModal
              isOpen={isAttachmentsModalOpen}
              onCancel={closeAttachmentsModal}
              attachments={attachments}
            />
          </>
        ) : (
          <NoResultsFound />
        )}
      </PhoenixBaseCard>
    </div>
  )
}

export default UnitStatReportsErrors
